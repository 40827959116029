<template>
  <page
    v-if="!$fetchState.pending && story.content.component"
    :key="story.content._uid"
    :name="story.name"
    :story="story"
  />
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '~/store/global'
import { initSmoothScroll } from '~/utils/scroll'
import { getDocs, searchDocs } from '~/plugins/meiliSearch'
const resolveRelations = [
  'NewsAndUpdates.posts',
  'CaseStudyCustomerDetails.company',
  'BlogPost.author',
  'FeaturedEvent.event',
  'EventLogoGrid.events'
]

export default {
  data() {
    return {
      story: { content: {} }
    }
  },
  computed: {
    ...mapState(useGlobalStore, ['loaded', 'queryParams']),
    version() {
      return this.isPreview ? 'draft' : 'published'
    },
    isPreview() {
      return !!(
        (process.client && window.location !== window.parent.location) ||
        this.$nuxt.context.isDev ||
        this.$route.query._storyblok ||
        this.$route.query.preview ||
        process.env.PREVIEW
      )
    },
    isProdSite() {
      const { host } = window.location
      return host === 'nacelle.com'
    }
  },
  mounted() {
    this.updateUrlParams()
    this.$storybridge(
      () => {
        // eslint-disable-next-line no-undef
        const storyblokInstance = new StoryblokBridge({
          resolveRelations
        })
        storyblokInstance.on('enterEditmode', (event) => {
          this.isProdSite && this.$fetch()
          this.getStory(event.storyId)
        })
        storyblokInstance.on(['input', 'published', 'change'], (event) =>
          this.handleEvent(event)
        )
      },
      (error) => {
        if (this.$nuxt.context.isDev && this.$route.query._storyblok) {
          this.logError(error)
        }
      }
    )

    initSmoothScroll(-120)
  },
  async fetch() {
    // check if global data has been loaded
    if (!this.loaded) {
      const globalsRes = await this.$storyapi.get('cdn/stories/global', {
        version: this.version
      })
      this.setGlobals(globalsRes.data.story.content)
      this.setLoaded(true)
    }

    // get content
    const slug = this.$route.path === '/' ? 'home' : this.$route.path

    let res
    try {
      res = await this.$storyapi.get(`cdn/stories/${slug}`, {
        version: this.version,
        resolve_relations: resolveRelations.join(',')
      })
      this.story = res.data.story
    } catch (e) {
      if (!e.response) {
        this.logError(res)
        this.$nuxt.context.error({
          statusCode: 404,
          message: 'Failed to receive content from api'
        })
      } else {
        this.logError(e.response.data)
        this.$nuxt.context.error({
          statusCode: e.response.status,
          message: e.response.data
        })
      }
    }
  },
  head() {
    const properties = {}
    const meta = []

    if (this.story.content && Object.keys(this.story.content).length !== 0) {
      const defaultTitle =
        'The Leading Headless Commerce Platform For Today’s Scaling eCommerce Brands | Nacelle'
      const {
        description,
        og_description: ogDescription,
        og_image: ogImage,
        title,
        og_title: ogTitle,
        twitter_description: twitterDescription,
        twitter_image: twitterImage,
        twitter_title: twitterTitle
      } = this.story.content && this.story.content.SEO
        ? this.story.content.SEO
        : {}

      properties.title = title || defaultTitle

      meta.push({
        hid: 'title',
        property: 'title',
        content: title || defaultTitle
      })

      meta.push({
        hid: 'og:title',
        property: 'og:title',
        content: ogTitle || defaultTitle
      })

      description &&
        meta.push({
          hid: 'description',
          name: 'description',
          content: description
        })

      ogDescription &&
        meta.push({
          hid: 'og:description',
          property: 'og:description',
          content: ogDescription
        })

      ogImage &&
        meta.push({
          hid: 'og:image',
          property: 'og:image',
          content:
            ogImage || `https://${process.env.BASE_URL}/metadata-nacelle.png`
        })

      meta.push({ property: 'og:site_name', content: 'Nacelle' })
    }
    return {
      ...properties,
      meta
    }
  },
  methods: {
    ...mapActions(useGlobalStore, [
      'setLoaded',
      'setGlobals',
      'setQueryParams'
    ]),
    getStory(storyId, version = 'draft') {
      return this.$storyapi
        .get(`cdn/stories/${storyId}`, {
          version: version,
          resolve_relations: resolveRelations.join(',')
        })
        .then(({ data }) => {
          this.story.content = data.story && data.story.content
        })
        .catch((error) => {
          console.error(error)
        })
    },
    handleEvent(event) {
      if (event.action === 'input') {
        if (event.story.id === this.story.id) {
          this.story.content = event.story.content
        }
      } else {
        this.$nuxt.$router.go({
          path: this.$nuxt.$router.currentRoute,
          force: true
        })
      }
    },
    logError(error) {
      // eslint-disable-next-line no-console
      console.error(error)
    },
    updateUrlParams() {
      if (!this.isEmpty(this.queryParams)) {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...this.queryParams
          }
        })
      } else {
        this.setQueryParams(this.$route.query)
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    }
  }
}
</script>
